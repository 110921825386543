import React from 'react'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { GlassMorphIcon } from '@/components/ui'
import { DemoHarvestSaleRequest } from '..'
import type { INoTemplatesNoticeProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const NoTemplatesNotice = ({ isAuthenticated, isAgrarian }: INoTemplatesNoticeProps) => {
  if (isAuthenticated && !isAgrarian)
    return (
      <div className="text_center">
        <h3 className="space-holder-top88-from-sm space-holder-top56-sm">{messages.notice}</h3>
      </div>
    )

  return (
    <div className="no-template-notice">
      <div className="no-template-notice__description">
        <GlassMorphIcon image="black/harvest" className="no-template-notice__icon" />
        <h3 className="no-template-notice__title">{messages.title}</h3>
        <span className="space-holder16-sm space-holder24-from-sm">{messages.description}</span>
        <Icon
          name={EIconName.ShevronDown}
          style={{ opacity: '0.5' }}
          size={EIconSize.L}
          className="space-holder24-from-sm space-holder-16-sm"
        />
      </div>
      <DemoHarvestSaleRequest isAgrarian={isAgrarian} />
    </div>
  )
}

export default NoTemplatesNotice
